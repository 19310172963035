<template>
  <v-row class="ves-questionnaire-panel reported_questionnaires">
    <v-col cols="12" class="sub-title ml-2">
      {{ `${$t("reported")}  ${$t("questionnaires")}` }}
    </v-col>
    <v-col cols="12">
      <v-expansion-panels class="pr-1" v-model="openPanel">
        <v-expansion-panel
          v-for="(questionnaire, i) in questionnaires"
          :key="i"
        >
          <v-expansion-panel-header
            class="pa-6"
            :expand-icon="openPanel == i ? 'mdi-minus' : 'mdi-plus'"
          >
            <span class="d-flex-inline pt-2 pb-2 date">
              {{ questionnaire.date }}
            </span>
            <span class="float-right flex-2 score">
              {{ $t("score") }} <strong>{{ questionnaire.score }}</strong>
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <h3 class="d-inline-block">{{ $t("patient_responses") }}</h3>
            <h4 class="float-right d-inline-block edit-response">
              <v-icon>icon-edit</v-icon> {{ $t("edit_responses") }}
            </h4>

            <v-container class="mt-10 pa-0 ma-0">
              <v-row>
                <v-col cols="12" class="pt-0 pb-0 mb-1">
                  <h4>Durante la última semana, ¿ha sido usted capaz de</h4>
                </v-col>
              </v-row>
              <v-row>
                <template v-for="(question, i) in questions">
                  <v-col
                    cols="12"
                    class="pt-0 pb-0"
                    v-if="question.type == 'MCQTable'"
                    :key="i"
                  >
                    <!-- MCQ Table question  -->
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left pr-15 pt-4 pb-2">
                              {{ question.title }}
                            </th>
                            <th
                              class="text-center text-uppercase"
                              v-if="i == 0"
                            >
                              Sin dificultad
                            </th>
                            <th
                              class="text-center text-uppercase"
                              v-if="i == 0"
                            >
                              Con alguna dificultad
                            </th>
                            <th
                              class="text-center text-uppercase"
                              v-if="i == 0"
                            >
                              Con mucha dificultad
                            </th>
                            <th
                              class="text-center text-uppercase"
                              v-if="i == 0"
                            >
                              Incapaz de hacerlo
                            </th>
                            <th
                              class="text-center text-uppercase"
                              v-if="i == 0"
                            >
                              unable to do
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(row, index) in question.data"
                            :key="index"
                          >
                            <td
                              v-for="(column, attrName) in row"
                              :key="attrName"
                            >
                              <span v-if="attrName == 'name'">
                                {{ column }}
                              </span>

                              <div
                                class="circle rounded-circle"
                                :class="{ selected: column }"
                                v-else
                              >
                                <v-icon v-if="column">icon-tick</v-icon>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </template>
              </v-row>

              <v-row class="checklists mt-10 pt-2">
                <template v-for="(question, i) in questions">
                  <!-- cehck list question  -->
                  <v-col
                    v-if="question.type == 'checkList'"
                    :key="i"
                    cols="6"
                  >
                    <div class="checklist-container ma-0 pa-0">
                      <p class="question-title">{{ question.title }}</p>

                      <div class="question container-fluid">
                        <v-row class="answer">
                          <v-col
                            cols="12"
                            class="mb-1"
                            v-for="(answer, index) in question.data"
                            :key="index + 1"
                          >
                            <div
                              class="circle rounded-circle d-inline-block"
                              :class="{ selected: answer.value }"
                            >
                              <v-icon v-if="answer.value">icon-tick</v-icon>
                            </div>
                            <div
                              class="ml-3 d-inline-block text-left"
                              :class="{
                                'font-weight-bold': answer.value,
                              }"
                            >
                              {{ answer.text }}
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HAQQuestionnairePanels",

  props: {
    title: {
      type: String,
      default: "",
    },
    questionnaires: {
      type: Array,
      default: () => [],
    },
    questions: {
      type: [Array, Object],
      default: () => [],
    },
  },

  data() {
    return {
      openPanel: null,
    };
  },
};
</script>


<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/questionnaires/components/haq-questionnaire-panels.scss">
</style>